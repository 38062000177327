import React, { useState } from 'react'
import { Container, makeStyles, TableCell, Typography, TableContainer, TableHead, TableRow, Paper, Table, TableBody, Button, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import createHashMap from '../../helpers/create-hash-map';
import { tableCss } from './../../components/Tables/TableCss';
import { BackNavAdmin, StepIdea } from '../../components/Shared';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { ideaStepFluxActions, ideaStepFluxUpdateActions } from '../../actions';
import { TableMenu } from '../../components/Tables/TableMenu';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { history } from '../../helpers';

import {FluxOfIdeaConfirmModal} from './FluxOfIdeaConfirmModal';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4)
  },
  btnNewArticle: {
    float: 'left',
    padding: theme.spacing(1, 2),
  },
}));

function FluxOfIdeasPage() {
  const { ideasStepsFlux } = useSelector((state) => state.domains);
  const classes = useStyles();
  const { t } = useTranslation();
  const linhasTable = { text: t('linhas_por_pagina') };
  const [confirmDeleteModal, setConfirmDeleteModal] = useState({
    open: false,
    itemId: null
  });
  const tableClasses = tableCss(linhasTable);
  const dispatch = useDispatch();

  const hashMapIdeiasFlux = createHashMap(ideasStepsFlux, '_id')

  const ideasStepFluxSorted = ideasStepsFlux ? ideasStepsFlux.sort((a, b) => {
    if (a.curationOrder > b.curationOrder) return 1;

    if (a.curationOrder < b.curationOrder) return -1;

    return 0;
  }) : []

  async function handleDelete(id) {
    await dispatch(ideaStepFluxActions.deleteStepFlux(id));
    setConfirmDeleteModal({ open: false, itemId: null });
  }

  function handleEdit(id) {
    if (id) {
      const ideaStepFlux = ideasStepsFlux.find((stepFlux) => stepFlux._id === id);
      let nextStepWhenApproved = ideaStepFlux.nextStepWhenApproved || '';
      let nextStepWhenRejected = ideaStepFlux.nextStepWhenRejected || '';
      let nextStepWhenPendingReview = ideaStepFlux.nextStepWhenPendingReview || '';
      let nextStepGeneric = ideaStepFlux.nextStepGeneric || '';

      dispatch(ideaStepFluxUpdateActions.setEditIdeaStepFluxAssociationsState({
        id,
        nextStepWhenApproved,
        nextStepWhenRejected,
        nextStepWhenPendingReview,
        nextStepGeneric
      }))
    }
    history.push(`/preferences/flux-of-ideas/edit/associations/${id}`);
  }

  function getGenericStep(ideaStepFlux) {
    if (ideaStepFlux && ideaStepFlux.decisions) {
      const nextStepGenericDecision = ideaStepFlux.decisions.find((decision) => decision.typeDecision === 'NextStepGeneric');

      if (nextStepGenericDecision) return nextStepGenericDecision.nextStepWhenGeneric;
    }

    return undefined;
  }

  function handleEditFlux(step) {
    history.push(`/preferences/flux-of-ideas/update/${step._id}`);
  }

  const tableInfo = {
    columns: [
      { id: 'step_name', label: t('step_name') },
      { id: 'next_step_when_approved', label: t('next_step_when_approved') },
      { id: 'next_step_when_rejected', label: t('next_step_when_rejected') },
      { id: 'next_step_when_pending_review', label: t('next_step_when_pending_review') },
      { id: 'next_step_when_generic', label: t('next_step_when_generic') },
      { id: 'actions', label: '' }
    ],
    data: ideasStepFluxSorted?.map(item => (
      <TableRow hover key={item._id} classes={{ root: tableClasses.tableRow }}>
        <TableCell classes={{ root: tableClasses.tableCellRadiusLeft }}>
          {/* <CustomTooltip title={item.name} aria-label={item.name} placement="bottom-start">
            <Typography variant="subtitle2" className={tableClasses.link} onClick={() => handleView(item._id)}>{item.name}</Typography>
          </CustomTooltip> */}
          <StepIdea step={item} />
        </TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{hashMapIdeiasFlux[item.nextStepWhenApproved] ? <StepIdea step={hashMapIdeiasFlux[item.nextStepWhenApproved]} /> : '-'}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{hashMapIdeiasFlux[item.nextStepWhenRejected] ? <StepIdea step={hashMapIdeiasFlux[item.nextStepWhenRejected]} /> : '-'}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{hashMapIdeiasFlux[item.nextStepWhenPendingReview] ? <StepIdea step={hashMapIdeiasFlux[item.nextStepWhenPendingReview]} /> : '-'}</TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}>{hashMapIdeiasFlux[getGenericStep(item)] ? <StepIdea step={hashMapIdeiasFlux[getGenericStep(item)]} /> : '-'}</TableCell>
        <TableMenu>
          <List>
            <ListItem onClick={() => handleEditFlux(item)} button>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography>{t('editar')}</Typography>} />
            </ListItem>
            <ListItem onClick={() => handleEdit(item._id)} button>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography>{t('editar_fluxo_associacao')}</Typography>} />
            </ListItem>
            <ListItem onClick={() => setConfirmDeleteModal({ open: true, itemId: item._id })} button>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography>{t('deletar')}</Typography>} />
            </ListItem>
          </List>
        </TableMenu>
      </TableRow>
    )),
  };

  return (
    <>
      <BackNavAdmin title={t('fluxos_de_aprovacao_ideias')} pathname="/" />
      <FluxOfIdeaConfirmModal
        isDelete={true}
        open={confirmDeleteModal.open}
        handleClose={() => setConfirmDeleteModal({ open: false, itemId: null })}
        handleConfirm={() => handleDelete(confirmDeleteModal.itemId)}
      />
      <Container maxWidth="lg" className={classes.root}>
        <Button
          className={classes.btnNewArticle}
          variant="contained"
          color="primary"
          type="submit"
          startIcon={<AddCircleIcon />}
          component={Link}
          to="/preferences/flux-of-ideas/new"
        >
          {t('new_idea_step_flux')}
        </Button>
        {/* <TablePanel tableInfo={tableInfo} /> */}
        <Paper className={tableClasses.paperRoot}>
          <TableContainer>
            <Table stickyHeader classes={{ root: tableClasses.table }}>
              <TableHead>
                <TableRow>
                  {tableInfo.columns.map((column) => (
                    <TableCell
                      key={column.id}
                      classes={{ root: tableClasses.tableCellHeader }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableInfo.data.map((item) => item)}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </>
  );
}

export { FluxOfIdeasPage };