import {
  Checkbox,
  FormControl,
  Input,
  ListItemText,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginRight: 0,
    },
    backgroundColor: theme.palette.neutrals.high.medium,
    borderRadius: 4,
    paddingRight: 10,
    paddingLeft: 10,
    marginRight: 16,
    paddingTop: 16,
    paddingBottom: 16,
  },
  select: {
    width: "100%",
    fontSize: 14,
  },
  icon: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  label: {
    position: 'absolute',
    fontSize: 14,
    top: 20,
    [theme.breakpoints.down('sm')]: {
      left: 10,
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

function MultiSelect({ id, defaultValues, items, onChange, ...props }) {
  const [value, setValue] = useState([]);
  const classes = useStyles(props);

  useEffect(() => {
    if (defaultValues.length > 0) return setValue(defaultValues);
  }, [defaultValues]);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      {!value.length && <div className={classes.label}>Todos</div>}
      <Select
        fullWidth
        labelId={`${id}-multiple-checkbox-label`}
        id={`${id}-multiple-checkbox`}
        multiple
        value={value}
        onChange={handleChange}
        input={<Input />}
        renderValue={(selected) => {
          return `${selected[0].name} ${
            selected.length > 1 ? `+${selected.length - 1}` : ''
          }`;
        }}
        MenuProps={MenuProps}
        classes={{ select: classes.select, icon: classes.icon }}
        disableUnderline
        style={{ textOverflow: 'ellipsis', width: '100%' }}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item}>
            <Checkbox color="primary" checked={value.indexOf(item) > -1} />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export { MultiSelect };
